body {
  height: 100vh;
  background-image: url("/public/BackgroundLong.png");
}

.container {
  height: 100%;
}

.list-group-flush > .list-group-item {
  background-color: #1e1e2d;
}

.btn.btn-sbot {
  color: #ffffff;
  background-color: #fd511a;
}

.border-sbot {
  border-color: #fd511a !important;
}
